<template>
  <r-page page-title="Add Voucher">
    <template #page-header>
      <h2 class="page-title">Voucher</h2>
      <r-menu-tab :level="3" />
    </template>
    <template #action-bar>
      <b-breadcrumb separator="has-succeeds-separator" size="is-small">
        <b-breadcrumb-item tag="router-link" to="/voucher">
          Voucher
        </b-breadcrumb-item>
        <b-breadcrumb-item
          tag="router-link"
          to="/voucher/add-voucher"
          active
          v-if="!isEditing"
        >
          Add Voucher
        </b-breadcrumb-item>
        <b-breadcrumb-item
          tag="router-link"
          to="/voucher/edit-voucher"
          active
          v-else
        >
          Edit Voucher
        </b-breadcrumb-item>
      </b-breadcrumb>
    </template>
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column is-4" />
        <div class="column is-4">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(submit)"
              class="is-label-14px-normal form-timeoff-type"
            >
              <div class="columns is-multiline">
                <div class="column is-12 voucher-title">
                  Voucher Information
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="voucher code"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Voucher Code"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input placeholder="e.g RSEBDG23" v-model="form.code" />
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="voucher name"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Voucher Name"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        placeholder="Enter Voucher Name"
                        v-model="form.name"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <b-field label="Voucher Description">
                    <b-input
                      type="textarea"
                      placeholder="Enter Description"
                      v-model="form.description"
                    />
                  </b-field>
                </div>
                <div class="column is-6">
                  <ValidationProvider
                    name="valid from"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Valid From"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-datepicker
                        placeholder="Date"
                        v-model="form.validFrom"
                        icon-right="calendar-blank"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-6">
                  <ValidationProvider
                    name="valid to"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Valid To"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-datepicker
                        placeholder="Date"
                        v-model="form.validTo"
                        icon-right="calendar-blank"
                        :min-date="form.validFrom"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <b-field label="Company Name">
                    <b-taginput
                      autocomplete
                      placeholder="Enter Company Name"
                      v-model="company"
                      open-on-focus
                      :data="companyList"
                      :check-infinite-scroll="true"
                      :loading="isFetchingCompany"
                      field="name"
                      @typing="getCompanyList"
                      @infinite-scroll="getMoreCompanyList"
                      :before-adding="beforeAdding"
                    >
                      <template slot-scope="props">
                        <div class="level">
                          <div class="level-left">
                            {{ props.option.name }}
                          </div>
                          <div
                            class="level-right"
                            v-if="isSelectedUser(props.option.id)"
                          >
                            <b-icon
                              icon="checkbox-marked-circle mt-0"
                              type="is-success"
                              size="is-small"
                            />
                          </div>
                        </div>
                      </template>
                    </b-taginput>
                  </b-field>
                </div>
                <div class="column is-12">
                  <b-switch
                    v-model="isQuotaLimitOn"
                    :left-label="true"
                    type="is-success"
                    class="voucher-quota-limit"
                    @input="switchToggle"
                  >
                    Quota Limit
                  </b-switch>
                  <ValidationProvider
                    name="quota limit"
                    v-slot="{ errors }"
                    rules="min_value:1|numeric"
                  >
                    <b-field
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="form.quotaLimit"
                        :disabled="!isQuotaLimitOn"
                        expanded
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="discount"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label="Percentage Discount"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        icon-right="percent-outline"
                        class="voucher-percentage-icon"
                        v-model="form.discount"
                        placeholder="Enter Discount Percentage"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <span class="is-pulled-right">
                    <b-button
                      type="is-primary"
                      outlined
                      tag="router-link"
                      to="/voucher"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      :disabled="isLoading"
                      :loading="isLoading"
                      native-type="submit"
                      type="is-primary"
                    >
                      {{ isEditing ? 'Save' : 'Add' }}
                    </b-button>
                  </span>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="column is-4" />
      </div>
    </template>
  </r-page>
</template>
<script>
import { mapActions } from 'vuex'
import { showToast } from '@/services/util'
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'
import ApiService from '../../../services/common/api.service'
export default {
  data() {
    return {
      form: {
        code: '',
        description: '',
        discount: '',
        id: 0,
        name: '',
        quotaLimit: null,
        validFrom: null,
        validTo: null,
      },
      company: [],
      isQuotaLimitOn: false,
      isEditing: false,
      companyList: [],
      isFetchingCompany: false,
      pageCompanyList: 1,
      lastPageCompanyList: 1,
      selectedCompany: [],
      searchCompany: '',
      isLoading: false,
    }
  },

  async mounted() {
    await this.loadVoucherDetail()
  },

  methods: {
    ...mapActions({
      actionLoadVoucherDetail: 'voucher/fetchVoucherDetail',
      saveVoucher: 'voucher/addVoucher',
      updateVoucher: 'voucher/editVoucher',
    }),
    async loadVoucherDetail() {
      let id = this.$route.params.id
      if (id) {
        this.isEditing = true

        let response = await this.actionLoadVoucherDetail(id)
        if (response && response.status === 200) {
          const data = response.data.data
          this.form = {
            code: data.code,
            description: data.description,
            discount: data.discount,
            id: data.id,
            name: data.name,
            quotaLimit: data.quotaLimit,
            validFrom: new Date(data.validFrom),
            validTo: new Date(data.validTo),
          }
          this.isQuotaLimitOn = !!+this.form.quotaLimit
          this.company = data.company ? data.company : []
        }
      }
    },

    async submit() {
      let companyIds = this.company.map((el) => {
        return el.id
      })
      const data = {
        code: this.form.code,
        description: this.form.description,
        discount: this.form.discount,
        name: this.form.name,
        quota_limit: this.form.quotaLimit,
        valid_from: moment(this.form.validFrom).format('YYYY-MM-DD'),
        valid_to: moment(this.form.validTo).format('YYYY-MM-DD'),
        companies: companyIds,
      }
      try {
        this.isLoading = true
        if (!this.isEditing) {
          let response = await this.saveVoucher(data)
          if (response && response.status === 201) {
            this.isLoading = false
            showToast('Add Success', 'is-success', 'is-top')
            this.$router.push('/voucher')
          }
        } else {
          let response = await this.updateVoucher({
            id: this.form.id,
            form: data,
          })
          if (response && response.status === 200) {
            this.isLoading = false
            showToast('Edit Success', 'is-success', 'is-top')
            this.$router.push('/voucher')
          }
        }
      } catch (error) {
        this.isLoading = false
        showToast(error.response.data.message, 'is-danger', 'is-top')
      }
    },

    switchToggle() {
      if (!this.isQuotaLimitOn) {
        this.form.quotaLimit = null
      }
    },

    isSelectedUser(id) {
      if (this.company.some((el) => el.id === id)) {
        return true
      }
      return false
    },

    beforeAdding(option) {
      let companyIds = this.company.map((el) => {
        return el.id
      })

      let companyIDCheck = !companyIds.includes(option.id)
      if (!companyIDCheck) {
        showToast('You already selected this company!', 'is-danger', 'is-top')
      }

      return companyIDCheck
    },

    getCompanyList: debounce(function (name) {
      if (this.selectedCompany !== name) {
        this.selectedCompany = name
        this.companyList = []
        this.pageCompanyList = 1
        this.lastPageCompanyList = 1
      }

      if (!name.length) {
        this.companyList = []
        this.pageCompanyList = 1
        this.lastPageCompanyList = 1
        return
      }

      if (this.pageCompanyList > this.lastPageCompanyList) {
        return
      }

      this.isFetchingCompany = true

      ApiService.get('/api/space-roketin/admin-internal/company', {
        search: this.selectedCompany,
        page: this.pageCompanyList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.companyList.push(item))
          this.pageCompanyList++
          this.lastPageCompanyList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingCompany = false
        })
    }, 500),
    getMoreCompanyList: debounce(function () {
      this.getCompanyList(this.selectedCompany)
    }, 250),
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-page',{attrs:{"page-title":"Add Voucher"},scopedSlots:_vm._u([{key:"page-header",fn:function(){return [_c('h2',{staticClass:"page-title"},[_vm._v("Voucher")]),_c('r-menu-tab',{attrs:{"level":3}})]},proxy:true},{key:"action-bar",fn:function(){return [_c('b-breadcrumb',{attrs:{"separator":"has-succeeds-separator","size":"is-small"}},[_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":"/voucher"}},[_vm._v(" Voucher ")]),(!_vm.isEditing)?_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":"/voucher/add-voucher","active":""}},[_vm._v(" Add Voucher ")]):_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":"/voucher/edit-voucher","active":""}},[_vm._v(" Edit Voucher ")])],1)]},proxy:true},{key:"page-content",fn:function(){return [_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"}),_c('div',{staticClass:"column is-4"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14px-normal form-timeoff-type",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 voucher-title"},[_vm._v(" Voucher Information ")]),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"voucher code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Voucher Code","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"e.g RSEBDG23"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"voucher name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Voucher Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Enter Voucher Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Voucher Description"}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Enter Description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"valid from","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Valid From","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Date","icon-right":"calendar-blank"},model:{value:(_vm.form.validFrom),callback:function ($$v) {_vm.$set(_vm.form, "validFrom", $$v)},expression:"form.validFrom"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"valid to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Valid To","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Date","icon-right":"calendar-blank","min-date":_vm.form.validFrom},model:{value:(_vm.form.validTo),callback:function ($$v) {_vm.$set(_vm.form, "validTo", $$v)},expression:"form.validTo"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Company Name"}},[_c('b-taginput',{attrs:{"autocomplete":"","placeholder":"Enter Company Name","open-on-focus":"","data":_vm.companyList,"check-infinite-scroll":true,"loading":_vm.isFetchingCompany,"field":"name","before-adding":_vm.beforeAdding},on:{"typing":_vm.getCompanyList,"infinite-scroll":_vm.getMoreCompanyList},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_vm._v(" "+_vm._s(props.option.name)+" ")]),(_vm.isSelectedUser(props.option.id))?_c('div',{staticClass:"level-right"},[_c('b-icon',{attrs:{"icon":"checkbox-marked-circle mt-0","type":"is-success","size":"is-small"}})],1):_vm._e()])]}}],null,true),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1)],1),_c('div',{staticClass:"column is-12"},[_c('b-switch',{staticClass:"voucher-quota-limit",attrs:{"left-label":true,"type":"is-success"},on:{"input":_vm.switchToggle},model:{value:(_vm.isQuotaLimitOn),callback:function ($$v) {_vm.isQuotaLimitOn=$$v},expression:"isQuotaLimitOn"}},[_vm._v(" Quota Limit ")]),_c('ValidationProvider',{attrs:{"name":"quota limit","rules":"min_value:1|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"disabled":!_vm.isQuotaLimitOn,"expanded":""},model:{value:(_vm.form.quotaLimit),callback:function ($$v) {_vm.$set(_vm.form, "quotaLimit", $$v)},expression:"form.quotaLimit"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"discount","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Percentage Discount","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{staticClass:"voucher-percentage-icon",attrs:{"icon-right":"percent-outline","placeholder":"Enter Discount Percentage"},model:{value:(_vm.form.discount),callback:function ($$v) {_vm.$set(_vm.form, "discount", $$v)},expression:"form.discount"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('span',{staticClass:"is-pulled-right"},[_c('b-button',{attrs:{"type":"is-primary","outlined":"","tag":"router-link","to":"/voucher"}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"native-type":"submit","type":"is-primary"}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Save' : 'Add')+" ")])],1)])])])]}}])})],1),_c('div',{staticClass:"column is-4"})])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }